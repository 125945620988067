<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/maintenance-list'" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export to Excel</a>
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/maintenance-list?maintenance_type='+search.maintenance_type+'&requested_by='+search.requested_by+'&requested_date='+search.requested_date+'&machine_code='+search.machine_code+'&problem='+search.problem+'&status='+search.status+'&notes='+search.notes+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Open"
          :value="(totalOpen)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="In Progress"
          :value="(totalInProgress)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Pending"
          :value="(totalPending)"
          iconClass="fa fa-hourglass-end"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Closed"
          :value="(totalClosed)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Maintenance List</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance Type</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Requested By</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Requested Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Machine / Asset</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Asset Location</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Problem</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Cause Analysis</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance Plan</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Target</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Closed Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Lead Time</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Improvement Results</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Notes</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Attachment</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger" v-if="row.maintenance_type == 'User Request' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-info" v-if="row.maintenance_type == 'Preventive' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-warning" v-if="row.maintenance_type == 'Scheduled' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-primary" v-if="row.maintenance_type == 'Predictive' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-success" v-if="row.maintenance_type == 'Corrective' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-secondary" v-if="row.maintenance_type == 'Breakdown' ">{{ row.request_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.maintenance_type }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.requested_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.requested_date).locale('id').format('LLL') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.machine_code }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.asset_location }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.problem }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.cause_analysis }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.maintenance_plan }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.due_date).locale('id').format('LLL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.closed_date).locale('id').format('LLL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.lead_time }} Hours</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.improvement_results }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Open' ">Open</span>
                        <span class="badge bg-gradient-info" v-if="row.status == 'In Progress' ">In Progress</span>
                        <span class="badge bg-gradient-warning" v-if="row.status == 'Pending' ">Pending</span>
                        <span class="badge bg-gradient-success" v-if="row.status == 'Closed' ">Closed</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.notes }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a :href="storageUrl+'image_maintenance_list/'+row.image" target="_BLANK" style="color: blue; size: 5px;">{{ row.image }}</a>
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                        <a style="margin-right: 20px;" :href="apiUrl+'print-pdf/print-maintenance-by-id?id='+row.id+''" target="_BLANK"><i class="fa fa-print"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3" v-if="form.title == 'Add Data'">Maintenance Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="maintenanceListData.maintenance_type" v-if="form.title == 'Add Data'">
          <option value="User Request">User Request</option>
          <option value="Preventive">Preventive</option>
          <option value="Scheduled">Scheduled</option>
          <option value="Predictive">Predictive</option>
          <option value="Corrective">Corrective</option>
          <option value="Breakdown">Breakdown</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Machine / Asset <span style="color: red;">*</span></label>
        <multiselect v-model="maintenanceListData.machine_code" :options="tableMachine.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Machine / Asset" label="machine_name" track-by="machine_name" :preselect-first="true" @keyup="getMachine">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Problem <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Problem" v-model="maintenanceListData.problem">
        <label for="example-text-input" class="form-control-label mt-3">Cause Analysis</label>
        <input type="text" class="form-control" placeholder="Cause Analysis" v-model="maintenanceListData.cause_analysis">
        <label for="example-text-input" class="form-control-label mt-3">Maintenance Plan</label>
        <input type="text" class="form-control" placeholder="Maintenance Plan" v-model="maintenanceListData.maintenance_plan">
        <label for="example-text-input" class="form-control-label mt-3">Target</label>
        <input type="datetime-local" class="form-control" placeholder="Target" v-model="maintenanceListData.due_date">
        <label for="example-text-input" class="form-control-label mt-3">Closed Date</label>
        <input type="datetime-local" class="form-control" placeholder="Closed Date" v-model="maintenanceListData.closed_date">
        <label for="example-text-input" class="form-control-label mt-3">Improvement Results</label>
        <input type="text" class="form-control" placeholder="Improvement Results" v-model="maintenanceListData.improvement_results">
        <label for="example-text-input" class="form-control-label mt-3">Notes</label>
        <input type="text" class="form-control" placeholder="Notes" v-model="maintenanceListData.notes">
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="maintenanceListData.status">
          <option value="Open">Open</option>
          <option value="In Progress">In Progress</option>
          <option value="Pending">Pending</option>
          <option value="Closed">Closed</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Image</label>
        <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Maintenance Type</label>
        <select class="form-select" aria-label="Default select example" v-model="search.maintenance_type" v-on:keyup.enter="get()">
          <option value="User Request">User Request</option>
          <option value="Preventive">Preventive</option>
          <option value="Scheduled">Scheduled</option>
          <option value="Predictive">Predictive</option>
          <option value="Corrective">Corrective</option>
          <option value="Breakdown">Breakdown</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Created By</label>
        <input type="text" class="form-control" placeholder="Created By" v-model="search.requested_by" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Created Date</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.requested_date" v-on:keyup.enter="get()"/>
        <!-- <input type="text" class="form-control" placeholder="Created Date" v-model="search.requested_date" v-on:keyup.enter="get()"> -->
        <label for="example-text-input" class="form-control-label mt-3">Machine / Asset</label>
        <input type="text" class="form-control" placeholder="Machine / Asset" v-model="search.machine_code" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Problem</label>
        <input type="text" class="form-control" placeholder="Problem" v-model="search.problem" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Open">Open</option>
          <option value="In Progress">In Progress</option>
          <option value="Pending">Pending</option>
          <option value="Closed">Closed</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Notes</label>
        <input type="text" class="form-control" placeholder="Notes" v-model="search.notes" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  
</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import maintenanceList from '@/services/maintenanceList.service';
import machineList from '@/services/machineList.service';

export default {
  name: "tables",
  components: {
    Card,
    ArgonButton,
    VueFinalModal,
    BasePagination,
    Multiselect,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tableMachine: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      maintenanceListData: {},
      search: {
        maintenance_type: '',
        requested_by: '',
        requested_date: '',
        machine_code: '',
        problem: '',
        status: '',
        notes: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      backgroundRed: null,
      requestedDate:'',

      totalOpen: '',
      totalInProgress: '',
      totalPending: '',
      totalClosed: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, maintenanceList.index({maintenance_type: this.search.maintenance_type, requested_by: this.search.requested_by, requested_date: this.search.requested_date, machine_code: this.search.machine_code, problem: this.search.problem, status: this.search.status, notes: this.search.notes, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page 

          context.totalOpen       = response.data.data.totalOpen
          context.totalInProgress = response.data.data.totalInProgress
          context.totalPending    = response.data.data.totalPending
          context.totalClosed     = response.data.data.totalClosed

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getMachine(param){
      let context = this;        
      Api(context, machineList.getMachine({search: param.target.value})).onSuccess(function(response) {    
          context.tableMachine.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableMachine.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.maintenanceListData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, maintenanceList.show(id)).onSuccess(function(response) {
        context.maintenanceListData              = response.data.data.data;
        context.maintenanceListData.machine_code = response.data.data.dataMachine[0];
        context.form.show                        = true;
        context.form.title                       = 'Edit Data';   

        context.requestedDate = response.data.data.data.requested_date
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.maintenanceListData.maintenance_type != undefined && context.maintenanceListData.machine_code != undefined && context.maintenanceListData.problem != undefined && context.maintenanceListData.status != undefined) {
        formData.append('maintenance_type', context.maintenanceListData.maintenance_type);
        formData.append('machine_code', JSON.stringify(context.maintenanceListData.machine_code));
        formData.append('problem', context.maintenanceListData.problem);
        formData.append('cause_analysis', (context.maintenanceListData.cause_analysis == undefined) ? '' : context.maintenanceListData.cause_analysis);
        formData.append('maintenance_plan', (context.maintenanceListData.maintenance_plan == undefined) ? '' : context.maintenanceListData.maintenance_plan);
        formData.append('due_date', (context.maintenanceListData.due_date == undefined) ? '' : context.maintenanceListData.due_date);
        formData.append('closed_date', (context.maintenanceListData.closed_date == undefined) ? '' : context.maintenanceListData.closed_date);
        formData.append('improvement_results', (context.maintenanceListData.improvement_results == undefined) ? '' : context.maintenanceListData.improvement_results);
        formData.append('notes', (context.maintenanceListData.notes == undefined) ? '' : context.maintenanceListData.notes);
        formData.append('status', context.maintenanceListData.status);
        formData.append('image', (context.maintenanceListData.image == undefined) ? '' : context.maintenanceListData.image);
        formData.append('lead_time', context.leadTime(context.requestedDate, context.maintenanceListData.closed_date));
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, maintenanceList.create(formData));
      } else {
          api = Api(context, maintenanceList.update(this.maintenanceListData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, maintenanceList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    leadTime(startDate, endDate){

      var start = moment(startDate, "YYYY-MM-DD H:i:s");

      // if (endDate != '0000-00-00') {
        var end = moment(endDate, "YYYY-MM-DD H:i:s");
        return moment.duration(end.diff(start)).asHours();
      // }else{
      //   var end2 = '2024-08-14';
      //   return moment.duration(end2.diff(start)).asDays();
      // }

    },
    filesChange(e) {
        this.maintenanceListData.image = e.target.files[0];
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, maintenanceList.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
